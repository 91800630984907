
.navbar_container{
    padding: 3rem 1rem 0 1rem;
    /* background-color: hsla(330,2.44%,16.08%,1); */
    display: flex;
    font-size: 2rem;
    align-items: center;
    justify-content: left;
    margin: 0;
    font-weight: 500;
    width: 100vw;
    height: 10vh;
}

    .navbar_title{
        color: white;
    }


    .navbar_socials{
        display: flex;
        flex-direction: row;
    }

        .navbar_social{
            color: white;
            list-style: none;
            padding-right: 1em;
        }
