body{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: white;
    width: 100vw;
    height: 100vh;
}

/* h1 {
    font-weight: 500;
    letter-spacing: 0em;
    font-size: 3rem;
} */


.image_background{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100vw;
    height: 100vh;
}

.main{
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

    .body_contents{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        margin-left: 2rem;
    }

        .title {
            /* color: white; */
            font-size: 3.5rem;
        }

        .phone{
            font-size: 2.5rem;
        }


.videos{
    background-color: hsla(330,2.44%,16.08%,1);
    color:white;
    padding: 2rem;
}
